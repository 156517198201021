// Wrapper for when navlinks need to be dynamically resolved between router-links and a tags
<template>
  <div>
    <a
      v-if="isExternal"
      :href="link"
      class="nav-link"
      target="_blank"
    >
      <slot />
    </a>

    <router-link
      v-else
      :to="link"
      class="nav-link"
    >
      <slot />
    </router-link>
  </div>
</template>

<script>

export default {
  name: 'NavLink',
  props: {
    link: {
      type: String,
      required: true,
      default: '',
    },
    isExternal: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
}
</script>

<style lang="stylus" scoped>
.nav-link {
  all: unset;
  display: flex;
  width: 100%;
  cursor: pointer;
}
</style>