// Input form component
<template>
  <form
    class="form-container"
    @submit.prevent="submitForm"
  >
    <div class="input-container">
      <label
        class="form-label"
        for="name"
      >
        Name:
      </label>
      <input
        v-model="formName"
        type="text"
        aria-label="name"
        name="name"
        class="form-singleline"
        :maxlength="singleLineMaxLength"
      >
    </div>
    <div class="input-container">
      <label
        class="form-label"
        for="email"
      >
        Email:
      </label>
      <input
        v-model="formEmail"
        type="text"
        aria-label="email"
        name="email"
        class="form-singleline"
        :maxlength="singleLineMaxLength"
      >
    </div>
    <div class="input-container">
      <label
        class="form-label"
        for="phone"
      >
        Phone (no spaces or special characters)
      </label>
      <input
        v-model="formPhone"
        type="tel"
        aria-label="phone"
        name="phone"
        class="form-singleline"
        :maxlength="phoneMaxLength"
        @keyup="validatePhone"
      >
    </div>
    <div class="input-container">
      <label
        class="form-label"
        for="message"
      >
        Add an optional message:
      </label>
      <textarea
        v-model="formText"
        type="text"
        aria-label="optional message"
        name="message"
        class="form-multiline"
        :maxlength="multiLineMaxLength"
      />
    </div>
    <div class="submit-container">
      <div class="submit-msg">
        <p>
          {{ formMsg }}
        </p>
      </div>
      <div>
        <button
          :disabled="isDisabled"
          type="submit"
          :class="`${btnStyle} no-highlight`"
        >
          submit
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Form',
  data () {
    return {
      formName: '',
      formEmail: '',
      formPhone: '',
      formText: '',
      formMsg: '',
      btnStyle: 'form-btn',
      phoneIsValid: false,
      isDisabled: false,
      singleLineMaxLength: 50,
      phoneMaxLength: 10,
      multiLineMaxLength: 240,
    }
  },
  methods: {
    submitForm () {
      // Does simple form validation before sending to server.
      if (this.formName.length < 1 || this.formEmail.length < 1 || this.formPhone.length < 1) {
        this.formMsg = 'Please make sure Name, Email, and Phone fields are not blank.'
        return
      }

      const emailRegex = new RegExp(/[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}/, 'gi')

      if (!emailRegex.test(this.formEmail)) {
        this.formMsg = 'Please use a valid email address.'
        return
      }

      if (!this.phoneIsValid) {
        this.formMsg = 'Please type in a 10-digit number without spaces or \nspecial characters.'
        return
      }

      // Set loading text and disabled button
      this.formMsg = 'Sending...'
      this.isDisabled = true
      this.btnStyle = 'btn-disabled'
      this.debounceSubmit()

      // Post form data
      axios.post(
        '/api/inquiry',
        {
          name: this.formName,
          email: this.formEmail,
          phone: this.formPhone,
          msg: this.formText,
        },
        {
          headers: {
            'x-api-key': process.env.VUE_APP_APIKEY,
          },
        },
      )
        .then((res) => this.formMsg = res.data.msg)
        .catch((err) => this.formMsg = err.data.msg)
    },
    validatePhone () {
      // Very simple phone validation
      const phoneRegex = new RegExp(/([0-9])/, 'gi')
      if (this.formPhone.length === this.phoneMaxLength) {
        const match = this.formPhone.match(phoneRegex)

        if (!match) {
          return
        }

        if (match.length === this.phoneMaxLength) {
          this.phoneIsValid = true
        }
      } else {
        this.phoneIsValid = false
      }
    },
    debounceSubmit () {
      // Adds a timer on form submission to mitigate bot submissions.
      setTimeout(() => {
        this.isDisabled = false
        this.btnStyle = 'form-btn'
      }, 5000)
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../css/components/Form.styl';
</style>