<template>
  <div
    class="page-content"
  >
    <!-- Left section -->
    <div
      class="content-section"
    >
      <h4>Welcome!</h4>
      <img
        src="../assets/images/glenn.webp"
        alt="Photograph of Glenn"
        class="img"
      >
      <p>{{ blurb }}</p>
      <p>
        Skills: 
        <strong>Vue.js,</strong> 
        <strong>Node.js,</strong> 
        <strong>Ruby on Rails, </strong> 
        <strong>MongoDB</strong> and 
        <strong>SQL</strong> app integrations, and 
        <strong>UI Design with Figma.</strong> 
        Experience with <strong>Encompass REST APIs</strong>. 
      </p>
    </div>
    <!-- Right section -->
    <div class="content-section raised">
      <h4>Get in Touch!</h4>
      <div class="contact-container">
        <Globe class="icon" />
        <div class="contact-text">
          <a href="mailto:glenn@piludu.io">glenn@piludu.io</a>
        </div>
      </div>
      <div class="contact-container">
        <Mailbox class="icon" />
        <div class="contact-text">
          <p class="address-one">
            P.O. Box 9426
          </p>
          <p class="address-two">
            Michigan City, IN 46361
          </p>
        </div>
      </div>
      <p>
        If you have project inquiries, job offers, or just want to have a chat, you may contact me directly or use the form below. I'd love to hear from you!
      </p>
      <Form />
    </div>
  </div>
</template>

<script>
import Mailbox from '../components/Mailbox.vue'
import Globe from '../components/Globe.vue'
import Form from '../components/Form.vue'

const blurb = 'Hi, I\'m Glenn. Thanks for visiting my site! I create web technology and would love to help you with your project. Whether you need just one component, or need an entire website or a complex web application, I can deliver quickly and without uneeded headache. If you are just in the beginning stages, I also offer wireframing and site mock-up services to help you hone in on what you want. Questions? Don\'t hesitate to contact me.'

export default {
  name: 'Home',
  components: {
    Mailbox,
    Form,
    Globe,
  },
  computed: {
    blurb () {
      return blurb
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../css/common.styl';
@import '../css/views/Home.styl';

.loading
  display: flex
  flex-wrap: wrap 
  justify-content: center
  align-items: center
  margin: 1rem 0
  padding: 1rem
  background-color: $blue
  box-shadow: $shadow

.loading__icon
  width: 2rem
  height: 2rem

</style>