<template>
  <div class="page">
    <Nav />

    <div class="page-container">
      <router-view />
    </div>
  </div>
</template>

<script>
import Nav from '@/components/Nav.vue'

export default {
  components: { Nav },
}
</script>

<style lang="stylus">
@import './css/base.styl';
</style>
