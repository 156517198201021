// Mailbox svg
<template>
  <div class="mail-icon">
    <svg viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M17,4H7A5,5 0 0,0 2,9V20H20A2,2 0 0,0 22,18V9A5,5 0 0,0 17,4M10,18H4V9A3,3 0 0,1 7,6A3,3 0 0,1 10,9V18M20,18H12V9C12,7.92 11.65,6.86 11,6H17A3,3 0 0,1 20,9V18M13,13H15V9H17V7H13V13M9,11H5V9H9V11Z"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Mailbox',
}
</script>

<style lang="stylus" scoped>
@import '../css/globals.styl';

.mail-icon {
  fill: $white;
}
</style>