<template>
  <header class="navbar">
    <NavLink
      class="home-link"
      link="/"
      :is-external="false"
    >
      {{ navText }}
    </NavLink>

    <button
      v-if="mobile"
      aria-label="Menu expansion button"
      class="expand-btn no-highlight"
      @click="showNav"
    >
      <Burger />
    </button>

    <nav :class="navDropdown">
      <ul>
        <!-- Internal to projects -->
        <li>
          <NavLink
            class="nav-link"
            link="/projects"
            :is-external="false"
          >
            projects
          </NavLink>
        </li>
        <!-- External to Github -->
        <li>
          <NavLink
            class="nav-link"
            link="https://github.com/DietBepis1"
            :is-external="true"
          >
            github
          </NavLink>
        </li>
        <!-- External to LinkedIn -->
        <li>
          <NavLink
            class="nav-link"
            link="https://www.linkedin.com/in/glenn-piludu"
            :is-external="true"
          >
            linkedIn
          </NavLink>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
import { useWindowSize } from 'vue-window-size'
import Burger from '@/components/Burger.vue'
import NavLink from './NavLink.vue'

export default {
  name: 'Nav',
  components: { 
    Burger,
    NavLink, 
  },
  data () {
    return {
      // determines if mobile nav button should be shown.
      mobile: true,
      navText: 'piludu.io',
      navHidden: true,
    }
  },
  computed: {
    navDropdown () {
      return this.navHidden ? 'nav-list nav-hidden' : 'nav-list'
    },
    windowSize () {
      return useWindowSize()
    },
  },
  watch: {
    windowSize: {
      handler (n) {
        if (n.width.value < 1200) {
          this.navHidden = true
          this.mobile = true
        } else {
          this.navHidden = false
          this.mobile = false
        }
      },
      deep: true,
    },
  },
  mounted () {
    if (this.windowSize.width.value >= 1200) {
      this.mobile = false
      this.navHidden = false
    }
  },
  methods: {
    showNav () {
      this.navHidden = !this.navHidden
    },
  },

}
</script>

<style lang="stylus" scoped>
@import '../css/components/Nav.styl';
</style>